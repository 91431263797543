import React, { useEffect } from "react";
import loadable from "@loadable/component";

const IonRangeSlider = loadable(() => import("../../utils/react-ion-slider"), {
  ssr: false,
});

const TransactionalEconomy = ({
  hydraInfo,
  hydraPrice,
  loading,
  transactionsPerSecond,
  setTransactionsPerSecond,
  hydraCustomPrice,
  setHydraCustomPrice,
}) => {
  const hydraMarketPrice = hydraPrice?.hydra?.usd || 0;
  const transationalEconomyPerBlock = transactionsPerSecond * 32 * 0.35;
  const burntPerBlock = transationalEconomyPerBlock / hydraCustomPrice;
  const sentToTokenCreators =
    (transactionsPerSecond * 32 * 0.125) / hydraCustomPrice;

  useEffect(() => {
    if (hydraMarketPrice) resetHydraMarketPrice();
  }, [hydraMarketPrice]);

  const resetHydraMarketPrice = () => {
    setHydraCustomPrice(hydraMarketPrice);
  };

  return (
    <div className="col-md-12 mb-5 mt-3">
      <div className="card shadow">
        <div className="card-header bg-light">
          <h5 style={{ position: "absolute", left: 10 }}>
            <span class="badge badge-secondary mr-2 badge-pill">2</span>
          </h5>
          <h5 className={"m-0 pl-4"}>
            Simulating Transactional Economy (Advanced Calculation)
          </h5>
        </div>
        <div className="card-body">
          <div className="row py-4 px-0 px-md-4">
            <div className="col-sm-12">
              <h6>
                HYDRA Market Price <sup>*</sup>
              </h6>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon2">
                    US$
                  </span>
                </div>
                <input
                  defaultValue={hydraMarketPrice}
                  value={hydraCustomPrice}
                  onChange={(e) => setHydraCustomPrice(e.target.value)}
                  type="number"
                  className="form-control"
                  aria-label="Market Price of HYDRA"
                  placeholder="Enter HYDRA price you want to simulate"
                  aria-describedby="basic-addon2"
                />
                <button
                  disabled={hydraCustomPrice == hydraMarketPrice}
                  onClick={() => resetHydraMarketPrice()}
                  className={`reset-btn btn btn-${
                    hydraCustomPrice == hydraMarketPrice
                      ? "secondary"
                      : "primary"
                  }`}
                >
                  Reset to Market Price
                </button>
              </div>
            </div>
          </div>
          <div className="row py-4 px-0 px-md-4">
            <div className="col-sm-12">
              <p style={{ lineHeight: 1, margin: 0, marginBottom: 10 }}>
                <small style={{ fontWeight: "bold" }}>
                  Transactions
                  <br />
                  per Second <sup>*</sup>
                </small>
              </p>

              <div>
                <div
                  style={{
                    left: "50%",
                    top: 0,
                    transform: "translateX(-50%)",
                    position: "absolute",
                  }}
                >
                  <p style={{ lineHeight: 1, marginBottom: 0 }}>
                    <small style={{ fontWeight: "bold" }}>
                      {transactionsPerSecond < 300 ? "Base" : "Elastic"}{" "}
                      Capacity
                    </small>
                  </p>
                  <p style={{ lineHeight: 1, marginBottom: 0 }}>
                    <small>
                      (Upto {transactionsPerSecond < 300 ? 300 : 2000} TPS)
                    </small>
                  </p>
                </div>
                {transactionsPerSecond < 300 && (
                  <div style={{ right: 0, top: -30, position: "absolute" }}>
                    <div style={{ textAlign: "right", fontSize: 20 }}>
                      <i className="flaticon-right"></i>
                    </div>
                    <p style={{ lineHeight: 1, marginBottom: 0 }}>
                      <small style={{ fontWeight: "bold" }}>
                        Elastic Capacity
                      </small>
                    </p>
                    <p style={{ lineHeight: 1, marginBottom: 0 }}>
                      <small>(Upto 2000 TPS)</small>
                    </p>
                  </div>
                )}
              </div>
              {transactionsPerSecond < 300 && (
                <IonRangeSlider
                  onChange={(value) => setTransactionsPerSecond(value.from)}
                  skin="round"
                  type={"single"}
                  min={0}
                  max={300}
                  from={transactionsPerSecond}
                  to={300}
                  step={0.1}
                  grid={true}
                />
              )}
              {transactionsPerSecond >= 300 && (
                <IonRangeSlider
                  onChange={(value) => setTransactionsPerSecond(value.from)}
                  skin="round"
                  type={"single"}
                  min={0}
                  max={2000}
                  from={transactionsPerSecond}
                  to={2000}
                  step={0.1}
                  grid={true}
                />
              )}
            </div>
          </div>

          <div className="row py-4 px-0 px-md-4">
            <div className="col-md-4 col-6 my-3 py-md-0">
              <div className="h-100 bg-light rounded p-3">
                <h6 className="font-weight-normal">Transactional Economy</h6>
                {loading ? (
                  <div class="spinner-grow" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  <>
                    <h4>
                      {transationalEconomyPerBlock.toFixed(2)}
                      <br />
                      USD
                    </h4>
                    <h6 className="text-secondary">per block</h6>
                  </>
                )}
              </div>
            </div>
            <div className="col-md-4 col-6 my-3 py-md-0">
              <div className="h-100 bg-light rounded p-3">
                <h6 className="font-weight-normal">Burnt per Block</h6>
                {loading ? (
                  <div class="spinner-grow" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  <>
                    <h4>{burntPerBlock.toFixed(4)} HYDRA</h4>
                    <h6 className="text-secondary">per block</h6>
                  </>
                )}
              </div>
            </div>
            <div className="col-md-4 my-3 py-md-0">
              <div className="h-100 bg-light rounded p-3">
                <h6 className="font-weight-normal">Sent to Token Creators</h6>
                {loading ? (
                  <div class="spinner-grow" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  <h4>
                    {sentToTokenCreators.toFixed(4)}
                    <br />
                    HYDRA
                  </h4>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionalEconomy;
