import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import PageBanner from "../components/Common/PageBanner";
import StakingCalculator from "../components/StakingCalculator";

const StakingCalculatorPage = ({ location }) => {
  return (
    <Layout>
      <Helmet>
        <title>Staking Calculator | HydraChain</title>
      </Helmet>
      <Navbar />
      <PageBanner pageTitle="Staking Calculator" />
      <StakingCalculator />
    </Layout>
  );
};

export default StakingCalculatorPage;
