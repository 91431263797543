import React, { useState } from "react";
import loadable from "@loadable/component";
const IonRangeSlider = loadable(() => import("../../utils/react-ion-slider"), {
  ssr: false,
});

const ChainState = ({
  hydraInfo,
  hydraCustomPrice,
  transactionsPerSecond,
  loading,
}) => {
  const blocksPerHydraPerYear = 985500;
  const [burnRate, setBurnRate] = useState(100);
  const [inflation, setInflation] = useState(20);
  const mintedHydraPerBlock =
    ((hydraInfo?.supply || 0) * inflation) / 100 / blocksPerHydraPerYear;
  const burntHydraPerBlock =
    (transactionsPerSecond * 32 * 0.35 * burnRate) / 100 / hydraCustomPrice;
  const changeInTotalSupply = mintedHydraPerBlock - burntHydraPerBlock;
  const stateOfChain =
    changeInTotalSupply > 0 ? "Inflationary" : "Deflationary";

  return (
    <div className="col-md-12 mt-3">
      <div className="card shadow">
        <div className="card-header bg-light">
          <h5 style={{ position: "absolute", left: 10 }}>
            <span class="badge badge-secondary mr-2 badge-pill">3</span>
          </h5>
          <h5 className={"m-0 pl-4"}>
            Chain State - Inflationary or Deflationary?
          </h5>
        </div>

        <div className="card-body">
          <div className="row px-4 py-2">
            <p>
              <small>
                The Hydra chain is fundamentally inflationary. However, the burn
                functionality can also turn it into a deflationary state, given
                that there is enough transactional economy. Play around with the
                following settings to simulate a deflationary state:
              </small>
            </p>
            <ol>
              <li>
                <p>
                  <small>More transactions per second</small>
                </p>
              </li>
              <li>
                <p>
                  <small>Higher Burn Rate</small>
                </p>
              </li>
              <li>
                <p>
                  <small>Lower Inflation Rate</small>
                </p>
              </li>
            </ol>
            <p>
              <small>
                All of these drive the "change in supply" down. The moment it
                turns <strong>negative</strong>, it means that more coins are
                burnt than minted, thus turning the chain deflationary.
              </small>
            </p>
          </div>

          <div className="row py-4 px-0 px-md-4">
            <div className="col-sm-12">
              <h6>
                Burn Rate <sup>*</sup>
              </h6>
              <IonRangeSlider
                postfix="%"
                onChange={(value) => setBurnRate(value.from)}
                skin="round"
                type={"single"}
                min={0}
                max={100}
                from={burnRate}
                to={100}
                step={1}
                grid={true}
              />
            </div>
          </div>

          <div className="row py-4 px-0 px-md-4">
            <div className="col-sm-12">
              <h6>
                Inflation <sup>*</sup>
              </h6>
              <IonRangeSlider
                postfix="%"
                onChange={(value) => setInflation(value.from)}
                skin="round"
                type={"single"}
                min={0}
                max={25}
                from={inflation}
                to={25}
                step={1}
                grid={true}
              />
            </div>
          </div>

          <div className="row py-4 px-0 px-md-4">
            <div className="col-6 my-3 py-md-0">
              <div className="bg-light h-100 rounded p-3">
                <h6>Minted Hydra</h6>
                {loading ? (
                  <div class="spinner-grow" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  <>
                    <h4>{mintedHydraPerBlock.toFixed(4)} HYDRA</h4>
                    <h6 className="text-secondary">per Block</h6>
                  </>
                )}
              </div>
            </div>
            <div className="col-6 my-3 py-md-0">
              <div className="bg-light h-100 rounded p-3">
                <h6>Burnt Hydra</h6>
                {loading ? (
                  <div class="spinner-grow" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  <>
                    <h4>{burntHydraPerBlock.toFixed(4)} HYDRA</h4>
                    <h6 className="text-secondary">per Block</h6>
                  </>
                )}
              </div>
            </div>
            <div className="col-6 my-3 py-md-0">
              <div className="bg-light h-100 rounded p-3">
                <h6>Change in Total Supply</h6>
                {loading ? (
                  <div class="spinner-grow" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  <>
                    <h4>{changeInTotalSupply.toFixed(4)} HYDRA</h4>
                    <h6 className="text-secondary">per Block</h6>
                  </>
                )}
              </div>
            </div>
            <div className="col-6 my-3 py-md-0">
              <div className="bg-light h-100 rounded p-3">
                <h6>State of Chain</h6>
                {loading ? (
                  <div class="spinner-grow" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  <h4>{stateOfChain}</h4>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChainState;
