import React, { useState } from "react";
import useFetch from "use-http";
import FixedBlockRewards from "./FixedBlockRewards";
import TransactionalEconomy from "./TransactionalEconomy";
import ChainState from "./ChainState";

const StakingCalculator = () => {
  const [transactionsPerSecond, setTransactionsPerSecond] = useState(1);
  const [hydraCustomPrice, setHydraCustomPrice] = useState(0);

  const {
    loading: loadingHydraInfo,
    error: errorHydraInfo,
    data: hydraInfo,
  } = useFetch("https://explorer.hydrachain.org/api/info", null, []);
  const {
    loading: loadingHydraPrice,
    error: errorHydraPrice,
    data: hydraPrice,
  } = useFetch(
    "https://api.coingecko.com/api/v3/simple/price?ids=hydra&vs_currencies=usd",
    null,
    []
  );

  return (
    <section className="services-details-area bg-f1f8fb ptb-100">
      <div className="container" style={{ maxWidth: 800 }}>
        <div className="row">
          <div className="col-md-12">
            <div className="alert bg-warning shadow mb-5">
              <small>
                This calculator is meant to provide you a rough estimate. Actual
                outcomes may differ based on various factors.
              </small>
            </div>
          </div>
          <FixedBlockRewards
            loading={loadingHydraInfo || loadingHydraPrice}
            hydraInfo={hydraInfo}
            hydraPrice={hydraPrice}
          ></FixedBlockRewards>
          <TransactionalEconomy
            loading={loadingHydraInfo || loadingHydraPrice}
            transactionsPerSecond={transactionsPerSecond}
            setTransactionsPerSecond={setTransactionsPerSecond}
            hydraCustomPrice={hydraCustomPrice}
            setHydraCustomPrice={setHydraCustomPrice}
            hydraInfo={hydraInfo}
            hydraPrice={hydraPrice}
          ></TransactionalEconomy>
          <ChainState
            loading={loadingHydraInfo || loadingHydraPrice}
            hydraInfo={hydraInfo}
            hydraCustomPrice={hydraCustomPrice}
            transactionsPerSecond={transactionsPerSecond}
          ></ChainState>
        </div>
      </div>
    </section>
  );
};

export default StakingCalculator;
