import React, { useState } from "react";

const FixedBlockRewards = ({ hydraInfo, hydraPrice, loading }) => {
  const blocksPerHydraPerYear = 985500;
  const [hydraStaked, setHydraStaked] = useState(1000);

  const netStakedWeight = ((hydraInfo?.circulatingSupply || 0) - 560000) * 0.94;
  const fixedStakingRewards =
    ((hydraInfo?.supply || 0) * 0.2) / blocksPerHydraPerYear;
  const yourShareOfTheNetwork = (hydraStaked / netStakedWeight) * 100;
  const apy =
    ((parseFloat(hydraInfo?.supply || 0) * 0.2) / netStakedWeight) * 100;
  const dailyIncome = (hydraStaked * apy) / 365 / 100;
  const dailyIncomeInUSD = dailyIncome * (hydraPrice?.hydra?.usd || 0);
  const secondsInADay = 3600 * 24;
  const expectedTimeToMineABlock =
    (netStakedWeight / hydraStaked) * (32 / secondsInADay);
  const timeToMineWithGoodLuck = 0;
  const timeToMineWithBadLuck = expectedTimeToMineABlock * 4.5;

  return (
    <div className="col-md-12 mb-5">
      <div className="card shadow">
        <div className="card-header bg-light">
          <h5 style={{ position: "absolute", left: 10 }}>
            <span class="badge badge-secondary mr-2 badge-pill">1</span>
          </h5>
          <h5 className={"m-0 pl-4"}>
            Simulating Staking Income (Simple Calculation)
          </h5>
        </div>
        <div className="card-body">
          <div className="row p-2">
            <div className="col-sm-12">
              <h6>How many HYDRA are you planning to stake? </h6>
              <div className="input-group mb-3">
                <input
                  min={0.01}
                  step={0.01}
                  value={hydraStaked}
                  onChange={(e) => setHydraStaked(e.target.value)}
                  type="number"
                  className="form-control"
                  aria-label="Amount of HYDRA you want to stake"
                  placeholder="Amount of HYDRA you want to stake?"
                  aria-describedby="basic-addon2"
                />
                <div className="input-group-append">
                  <span className="input-group-text" id="basic-addon2">
                    HYDRA
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-4 pb-4">
            <div className="row p-4 bg-light">
              <div className="col-md-8 align-self-center">
                <h6>Fixed Staking Rewards</h6>
                <p className="text-secondary" style={{ maxWidth: 600 }}>
                  <small>
                    This is the base-reward when mining a block. Any income from
                    the transactional economy will be on top of that.
                  </small>
                </p>
              </div>
              <div className="col-md-4 align-self-center text-right mt-3 mt-md-0">
                {loading ? (
                  <div class="spinner-grow" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  <>
                    <h4 className="text-left text-md-right">
                      {fixedStakingRewards.toFixed(4)} HYDRA
                    </h4>
                    <h6 className="text-left text-md-right text-secondary">
                      per block
                    </h6>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="pb-4">
            <div className="row p-0 p-md-4">
              <div className="col-md-4 col-6 my-3 my-md-0 ">
                <div className="bg-light p-3 h-100 rounded">
                  <h6 className="font-weight-normal">
                    Your Share of the Network
                  </h6>
                  {loading ? (
                    <div class="spinner-grow" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <h4>{yourShareOfTheNetwork.toFixed(9)} %</h4>
                  )}
                </div>
              </div>
              <div className="col-md-4 col-6 my-3 my-md-0 ">
                <div className="bg-light p-3 h-100 rounded">
                  <h6 className="font-weight-normal">Daily Rewards</h6>
                  {loading ? (
                    <div class="spinner-grow" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <>
                      <h4>{dailyIncome.toFixed(4)} HYDRA</h4>
                      <p className="text-secondary font-weight-bold">
                        (US${dailyIncomeInUSD.toFixed(2)})
                      </p>
                    </>
                  )}
                </div>
              </div>
              <div className="col-md-4 col-6 my-3 my-md-0 ">
                <div className="bg-light p-3 h-100 rounded">
                  <h6 className="font-weight-normal">
                    Annual Percentage Return
                  </h6>
                  {loading ? (
                    <div class="spinner-grow" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <h4>{apy.toFixed(2)} %</h4>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row px-md-4 px-0 py-4 bg-light">
            <div className="col-md-12 mb-2 text-center">
              <h6 className={"text-center"}>Expected Time to Mine a Block</h6>
              {loading ? (
                <div class="spinner-grow" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              ) : (
                <h4 className={"text-center"}>
                  {expectedTimeToMineABlock.toFixed(2)} Days
                </h4>
              )}
            </div>

            {!loading && (
              <>
                <div className="col-md-12">
                  <div
                    class="progress"
                    style={{
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    }}
                  >
                    <div
                      class="progress-bar bg-success"
                      role="progressbar"
                      aria-valuenow="15"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <div
                        style={{
                          height: 48,
                          position: "absolute",
                          left: "22%",
                          border: "1px dashed #ccc",
                        }}
                      ></div>

                      <span
                        className="d-inline-block align-top text-center"
                        style={{ width: "22%", position: "relative" }}
                      >
                        <p style={{ lineHeight: 1 }}>
                          <small>
                            Good Luck
                            <br />({timeToMineWithGoodLuck}-
                            {expectedTimeToMineABlock.toFixed(2)} days)
                          </small>
                        </p>
                      </span>
                      <span
                        className="d-inline-block align-top text-center"
                        style={{ width: "78%" }}
                      >
                        <p style={{ lineHeight: 1 }}>
                          <small>
                            Bad Luck
                            <br />({expectedTimeToMineABlock.toFixed(2)}-
                            {timeToMineWithBadLuck.toFixed(2)} days)
                          </small>
                        </p>
                      </span>
                    </div>
                  </div>
                  <div
                    className="d-none d-sm-block"
                    style={{ display: "relative", width: "100%", height: 30 }}
                  >
                    <div style={{ position: "absolute", left: 0 }}>
                      <small>{timeToMineWithGoodLuck} days</small>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        left: "calc(22% - 28.5px)",
                      }}
                    >
                      <small>{expectedTimeToMineABlock.toFixed(2)} days</small>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        left: "calc(100% - 67.48px)",
                      }}
                    >
                      <small>{timeToMineWithBadLuck.toFixed(2)} days*</small>
                    </div>
                  </div>
                </div>

                <p
                  className="mt-3 text-center text-secondary"
                  style={{
                    lineHeight: 1,
                    maxWidth: 500,
                    margin: "auto",
                    fontSize: "1rem",
                  }}
                >
                  <small>
                    *There is a 99% chance that you will mine a block in this
                    time. There is a 1% chance for it to take longer.
                  </small>
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FixedBlockRewards;
